<template>
  <ErrorPage>
    <template v-slot:header>
      <div v-if="isMultiFlights">
        {{ $t('exception.flight_not_found', { s: flightsString }) }}
      </div>
      <div v-else>
        {{
          $t('exception.multi_flights_not_found', {
            fn: flightNumber,
            fd: flightDate,
          })
        }}
      </div>
    </template>
    <v-container class="mt-3">
      <v-row>
        <v-col>
          <p class="py-2">
            {{ $t('exception.unavailable_flight') }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <template v-slot:footer>
      <v-btn
        depressed
        color="primary"
        class="text-capitalize dnf-gotohome"
        @click="goToHomePage()"
        >{{ $t('exception.go_to_homepage') }}
      </v-btn>
    </template>
  </ErrorPage>
</template>

<script>
import ErrorPage from './ErrorPage.vue';

export default {
  name: 'FlightNotFound',
  components: { ErrorPage },
  props: {
    flightNumber: {
      default: 'KM376',
    },
    flightDate: {
      default: '2020-07-01',
    },
  },
  methods: {
    goToHomePage() {
      this.$router.push({ name: 'concierge-discover' });
    },
  },
  computed: {
    isMultiFlights() {
      return Array.isArray(this.flightNumber) && Array.isArray(this.flightDate);
    },
    flightsString() {
      return this.flightNumber
        .map((flightNum, i) => `${flightNum} ${this.flightDate[i]}`)
        .join(', ');
    },
  },
};
</script>

<style scoped></style>
